.App {
  text-align: center;
  background:#10290F;
  
  min-height: 90vh;
}

body{
  background:black;
}

 