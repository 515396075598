.winner-display-container{
    color:white;
    display:flex;
    align-items: center;
    flex-direction: column;
    background-color:black;
    height:100%;
    padding:20px;
}

.user-btn-container{
    display:flex;
    
    justify-content: space-around;
}

input[type='image'] {
    width: 80px;
}
.score-display{
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: space-around;
}
.score-box{
    display:flex;
    flex-direction: row;
}

.chip-display{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    font-size: 25px;
}
.chip-display-text{
    font-family: 'Orbitron', sans-serif;
    font-size: 25px;
}
.chips-box{
    padding:10px;
}
#chip-double{
    margin-left:10px;
}

.control-btn{
    -moz-box-shadow: 0px 1px 0px 0px #fff6af;
	-webkit-box-shadow: 0px 1px 0px 0px #fff6af;
	box-shadow: 0px 1px 0px 0px #fff6af;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffec64), color-stop(1, #ffab23));
	background:-moz-linear-gradient(top, #ffec64 5%, #ffab23 100%);
	background:-webkit-linear-gradient(top, #ffec64 5%, #ffab23 100%);
	background:-o-linear-gradient(top, #ffec64 5%, #ffab23 100%);
	background:-ms-linear-gradient(top, #ffec64 5%, #ffab23 100%);
	background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffec64', endColorstr='#ffab23',GradientType=0);
	background-color:#ffec64;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #ffaa22;
	display:inline-block;
	cursor:pointer;
	color:#333333;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
}
.control-btn:hover{
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffab23), color-stop(1, #ffec64));
	background:-moz-linear-gradient(top, #ffab23 5%, #ffec64 100%);
	background:-webkit-linear-gradient(top, #ffab23 5%, #ffec64 100%);
	background:-o-linear-gradient(top, #ffab23 5%, #ffec64 100%);
	background:-ms-linear-gradient(top, #ffab23 5%, #ffec64 100%);
	background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffab23', endColorstr='#ffec64',GradientType=0);
	background-color:#ffab23;
}
.control-btn:active{
    position:relative;
	top:1px;
}
.winner-buster-display{
    font-size:25px;
    padding:10px;
}
@media(max-width:768px){
    .score-display{
        flex-direction: row;
        margin:auto 10px;
    }
    input[type='image'] {
        width: 60px;
    }

    .winner-display-container{        
        padding:10px;
        font-size:10px;
        
        
    }
    .winner-buster-display{
        font-size:20px;
    }
}